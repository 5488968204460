import React from 'react'
import { RichText } from 'prismic-reactjs'
import Linked from "../partials/linked"
import { linkResolver } from "../../utils/linkResolver"


export default ({ slice }) => (

  <div className="py-8">
    <div className="px-6 py-8 bg-white max-w-2xl mx-auto md:px-8">
        <h2 className="mt-20 font-caps uppercase tracking-widest">{ slice.primary.links_title }</h2>
        <div className="mt-8 richtext">{ RichText.render(slice.primary.links_description, linkResolver) }</div>
        <div className="mt-4">
            {
                slice.fields && slice.fields.map(( field, index ) => (
                    <div key={index} className="">
                        {field.lesson && <Linked route={field.lesson} /> }
                    </div>
                ))
            }
        </div>
    </div>
  </div>
)