import React, { useLayoutEffect } from 'react'
import Player from '@vimeo/player'

export default ({ video }) => {

    useLayoutEffect(() => { 

        var iframe = document.getElementById(video.video_id).firstChild;
        var player = new Player(iframe)
        player.play();
        
    })


    return (
        
        <div id={video.video_id} className="vimeo" dangerouslySetInnerHTML={{ __html: video.html }} />
    
    )

}