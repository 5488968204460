import React from 'react'

export default ({ image, classes }) => (

    <picture>
        <source media="(max-width: 640px)" srcSet={image.xs.url} height={image.xs.dimensions.height} width={image.xs.dimensions.width} />
        <source media="(max-width: 768px)" srcSet={image.sm.url} height={image.sm.dimensions.height} width={image.sm.dimensions.width} />
        <source media="(max-width: 1024px)" srcSet={image.md.url} height={image.md.dimensions.height} width={image.md.dimensions.width} />
        <source media="(max-width: 1280px)" srcSet={image.lg.url} height={image.lg.dimensions.height} width={image.lg.dimensions.width} />
        <img src={image.xl.url} alt={image.alt} width={image.xl.dimensions.width} height={image.xl.dimensions.height} className={classes} />
    </picture>

)