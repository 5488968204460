import React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from "../../utils/linkResolver"
import Linked from "../partials/linked"

export default ( {slice} ) => (


<div className="bg-gray-50 pt-12 sm:pt-16">
    <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl leading-9 font-display text-gray-900 sm:text-4xl sm:leading-10">
                {slice.primary.summary_title}
            </h2>
            <p className="mt-3 text-lg leading-7 font-display text-gray-500 sm:mt-4">
                {slice.primary.summary_subtitle}
            </p>
        </div>
    </div>
    <div className="mt-10 pb-12 bg-white sm:pb-16">
        <div className="relative">
            <div className="absolute inset-0 h-1/2 bg-gray-50"></div>
            <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-4xl mx-auto">
                    <div className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                        <div className="border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                            <p className="text-5xl leading-none font-extrabold text-canred">
                                {slice.primary.total_time} 
                                <span className="text-2xl">{slice.primary.total_unit}</span>
                            </p>
                            <p className="mt-2 text-lg leading-6 font-medium text-gray-500">
                                Dauer
                            </p>
                        </div>
                        <div className="border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                            <p className="text-5xl leading-none font-extrabold text-canred">
                                {slice.primary.active_time}
                                <span className="text-2xl">{slice.primary.active_unit}</span>

                            </p>
                            <p className="mt-2 text-lg leading-6 font-medium text-gray-500">
                                Aktive Zeit
                            </p>
                        </div>
                        <div className="border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                            <p className="text-5xl leading-none font-extrabold text-canred">
                                {slice.primary.level}
                            </p>
                            <p className="mt-2 text-lg leading-6 font-medium text-gray-500">
                                Level
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="max-w-2xl mx-auto px-6 mt-12">   
            <h3 className="uppercase font-caps tracking-wider">{slice.primary.summary_heading}</h3> 
            <div className="text-lg font-display richtext">
                { RichText.render(slice.primary.summary_description, linkResolver) }
            </div>
            <div className="grid grid-cols-2">
                <div className="mt-4">
                    <h3 className="mt-8 uppercase font-caps tracking-wider">Techniken</h3> 
                    <div className="mt-4">
                    {
                        slice.fields && slice.fields.map(( field, index ) => (
                            <div key={index} className="">
                                    {field.lesson.lesson_type === 'Techniken' &&
                                        <Linked route={field.lesson} /> 
                                    }
                            </div>
                        ))
                    }
                    </div>
                </div>
                <div className="mt-4">
                    <h3 className="mt-8 uppercase font-caps tracking-wider">Utensilien</h3>
                    <div className="mt-4"> 
                    {
                        slice.fields && slice.fields.map(( field, index ) => (
                            <div key={index} className="">
                                    {field.lesson.lesson_type === 'Utensilien' &&
                                        <Linked route={field.lesson} /> 
                                    }
                            </div>
                        ))
                    }
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


)

