import React from 'react'
import Linked from "../partials/linked"

export default ({ slice }) => (

<div className="py-8 bg-gray-50 border-t border-b border-gray-200">
  <div className="px-6 py-8 max-w-2xl mx-auto md:px-8">
    <h2 className="mt-20 font-caps uppercase tracking-widest">{ slice.primary.ingredients_title }</h2>
    <div className="mt-8">
        <div className="flex flex-col">
            <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div className="align-middle inline-block min-w-full">

                    <table className="text-lg">
                        <tbody>
                        { slice.fields && slice.fields.map(( field, index ) => (

                            <tr key={index}>
                                <td className="whitespace-no-wrap font-medium">{field.ingredient_amount}</td>
                                <td className="pl-2 whitespace-no-wrap font-medium">{field.ingredient_unit}</td>
                                <td className="pl-8 whitespace-no-wrap font-display ">{field.ingredient_title}</td>
                            </tr>
                        ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    {
        slice.primary.lesson &&
        <div className="mt-4">
            {<Linked route={slice.primary.lesson} /> }
        </div>
    }
  </div>
</div>
)

