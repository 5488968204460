import React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from "../../utils/linkResolver"


export default ({ slice }) => (
<div className="py-8">
  <div className="px-6 py-8 bg-white max-w-2xl mx-auto md:px-8">
        <h2 className="font-bold font-caps uppercase tracking-widest">{slice.primary.text_title}</h2>
        <div className="mt-8 richtext">{ RichText.render(slice.primary.text_body, linkResolver) }</div>
        {
            slice.primary.text_video && 
            <div className="mt-8 shadow-lg rounded-lg overflow-hidden">
                <div className="vimeo" dangerouslySetInnerHTML={{ __html: slice.primary.text_video.html }} />
            </div>
        }
  </div>
</div>

)