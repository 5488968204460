import React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from "../../utils/linkResolver"


export default ({ slice }) => (

  <div className="py-8">
    <div className="px-6 py-8 bg-white max-w-4xl mx-auto md:px-8">
        <p className="mt-20 font-caps uppercase tracking-widest text-gray-600 text-sm">Tips</p>
        <h2 className="font-caps uppercase tracking-wider">{ slice.primary.tips_title }</h2>
        <div className="mt-8 richtext">{ RichText.render(slice.primary.tips_description, linkResolver) }</div>
        { slice.primary.tips_layout === 'grid' && 
            <div className="grid grid-cols-1 md:grid-cols-2 row-gap-4 col-gap-12">
                {
                    slice.fields && slice.fields.map(( field, index ) => (
                        <div key={index} className="">
                            <h3 className="mt-4 font-caps uppercase tracking-widest">{field.tip_title}</h3>
                            <div className="richtext">{ field.tip_description && RichText.render(field.tip_description) }</div>
                            {
                                field.tip_alt && 
                                <div className="">{field.tip_alt}</div>
                            }
                            {
                                field.tip_video && 
                                <div className="">
                                    <div dangerouslySetInnerHTML={{ __html: field.tip_video.html }} />
                                </div>
                            }
                        </div>
                    ))
                }
            </div>
        }
        { slice.primary.tips_layout === 'list' && 
            <div className="">
                <div className="mt-6 pt-6">
                    <dl>
                        {
                        slice.fields && slice.fields.map(( field, index ) => (
                            <div key={index} className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                                <dt className="mt-1 text-lg font-caps uppercase tracking-widest md:text-sm tracking-wide text-gray-900 md:col-span-5">
                                {field.tip_title}
                                </dt>
                                <dd className="mt-2 md:mt-0 md:col-span-7">
                                    <div className="richtext">
                                        { field.tip_description && RichText.render(field.tip_description) }
                                    </div>
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        }

    </div>
  </div>
)

