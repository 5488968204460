import React from 'react'
import { RichText } from 'prismic-reactjs'

export default ({ slice }) => (
<div className="py-8 md:bg-gray-50 relative">
  <div className="hidden md:block absolute inset-0">
      <div className="bg-white h-1/3 sm:h-2/3"></div>
  </div>
  <div className="relative px-6 py-8 max-w-2xl md:border md:border-gray-100 mx-auto bg-white sm:px-8 md:rounded-lg md:shadow-xl">
      <div className="mt-20">
        <div className="flex items-center mx-auto">
          <svg className="h-12 w-12 text-green-400" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.46a1 1 0 0 1 1.42-1.42 3 3 0 0 0 4.24 0 1 1 0 0 1 1.42 1.42 5 5 0 0 1-7.08 0zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
          </svg>
          <h3 className="ml-2 font-caps uppercase tracking-widest">So ist es super</h3>
        </div>
        <div className="mt-4 richtext">{ RichText.render(slice.primary.good) }</div>
      </div>
      <div className="mt-8 ">
        <div className="flex items-center mx-auto">
          <svg className="h-12 w-12 text-canred" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.54a5 5 0 0 1 7.08 0 1 1 0 0 1-1.42 1.42 3 3 0 0 0-4.24 0 1 1 0 0 1-1.42-1.42zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
          </svg>
          <h3 className="ml-2 font-caps uppercase tracking-widest">So ist nicht so super</h3>
        </div>
        <div className="mt-4 richtext">{ RichText.render(slice.primary.bad) }</div>
      </div>
  </div>
</div>
)