import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Ingredients from '../components/slices/ingredients'
import Step from "../components/slices/step"
import Criteria from "../components/slices/criteria"
import Text from "../components/slices/text"
import Tips from "../components/slices/tips"
import Links from "../components/slices/links"
import { RichText } from 'prismic-reactjs'
import Summary from '../components/slices/summary'
import Imgix from "../components/imgix"

export const query = graphql`
query LessonsQuery($uid: String) {
    prismic {
        allLessons(uid: $uid) {
            edges {
                node {
                    _meta {
                        id
                        uid
                        type
                    }
                    title
                    subtitle
                    beauty
                    description
                    lesson_type
                    body {
                        ... on PRISMIC_LessonBodySummary {
                            type
                            label
                            primary {
                                total_time
                                total_unit
                                active_time
                                active_unit
                                level
                                summary_title
                                summary_subtitle
                                summary_heading
                                summary_description
                            }
                            fields {
                                lesson {
                                    ... on PRISMIC_Lesson {
                                        title
                                        lesson_type
                                        _meta {
                                            id
                                            uid
                                            type
                                            lang
                                        }
                                    }
                                }
                            }
                        }
                        ... on PRISMIC_LessonBodyIngredients {
                            type
                            label
                            primary {
                                ingredients_title
                                lesson {
                                    ... on PRISMIC_Lesson {
                                        title
                                        _meta {
                                            id
                                            uid
                                            type
                                            lang
                                        }
                                    }
                                }
                            }
                            fields {
                                ingredient_title
                                ingredient_amount
                                ingredient_unit
                                lesson {
                                    ... on PRISMIC_Lesson {
                                        title
                                        _meta {
                                            id
                                            uid
                                            type
                                            lang
                                        }
                                    }
                                }
                            }
                        }
                        ... on PRISMIC_LessonBodySteps {
                            type
                            label
                            primary {
                                step_title
                                step_description
                                lesson {
                                    ... on PRISMIC_Lesson {
                                        title
                                        _meta {
                                            id
                                            uid
                                            type
                                            lang
                                        }
                                    }
                                }
                            }
                            fields {
                                step_alt
                                step_caption
                                step_video
                                step_image        
                            }
                        }
                        ... on PRISMIC_LessonBodyCriteria {
                            type
                            label
                            primary {
                                bad
                                good
                            }
                        }
                        ... on PRISMIC_LessonBodyText {
                            type
                            label
                            primary {
                                text_body
                                text_title
                                text_video
                            }
                        }
                        ... on PRISMIC_LessonBodyTips {
                            type
                            label
                            primary {
                                tips_description
                                tips_title
                                tips_layout
                            }
                            fields {
                                tip_alt
                                tip_description
                                tip_image
                                tip_title
                                tip_video
                            }
                        }
                        ... on PRISMIC_LessonBodyLinks {
                            type
                            label
                            primary {
                                links_title
                                links_description
                            }
                            fields {
                                lesson {
                                    ... on PRISMIC_Lesson {
                                        title
                                        _meta {
                                            id
                                            uid
                                            type
                                            lang
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
`


const LessonSlices = ({ slices }) => {

    if(!slices) return null;
    
    const isStep = (e) => e.type === 'steps'
    const firstStep = slices.findIndex(isStep)
    const totalSteps = slices.filter(slice => slice.type === 'steps').length
    
    return slices.map((slice, index) => {
        const res = (() => {
            switch(slice.type) {
                case 'ingredients' : return (
                    <div key={index}>
                        { <Ingredients slice={ slice } />}
                    </div>
                )

                case 'summary' : return (
                    <div key={index}>
                        { <Summary slice={ slice } />}
                    </div>
                )

                case 'steps' : return (
                    <div className="step" key={index}>
                        { <Step slice={ slice } first_step={firstStep} total_steps={totalSteps} step={index} />}
                    </div>
                )

                case 'criteria' : return (
                    <div key={index}>
                        { <Criteria slice={ slice } />}
                    </div>
                )

                case 'text' : return (
                    <div key={index}>
                        { <Text slice={ slice } />}
                    </div>
                )

                case 'tips' : return (
                    <div key={index}>
                        { <Tips slice={ slice } />}
                    </div>
                )

                case 'links' : return (
                    <div key={index}>
                        { <Links slice={ slice } />}
                    </div>
                )

                default: return
            }
        })();
        return res;
    })
}

const LessonBody = ({ lessonPage }) => {
    const titled = lessonPage.title.length !== 0 ;
    return (
        <Layout title={titled ? lessonPage.title : 'Rezept'}>
            <div className="pb-48">
                <div className="px-6 py-8 mx-auto bg-white md:px-8 lg:max-w-xl">
                    <div className="pb-12 border-b md:pb-20">
                        <Link to="/" className="block mt-6 font-caps uppercase tracking-widest text-sm text-gray-400 md:mt-16">{lessonPage.lesson_type}  /  <span className="text-gray-900">{lessonPage.title}</span></Link> 
                        <h1 className="mt-24 text-4/5xl font-medium font-display leading-none md:mt-32">{ titled ? lessonPage.title : 'Untitled' }</h1>
                        <p className="mt-8 text-xl max-w-2xl leading-relaxed font-display text-canred md:mt-6 lg:mt-8">{ lessonPage.subtitle }</p>
                    </div>
                    <div className="mt-8 md:mt-12 richtext">{ lessonPage.description && RichText.render(lessonPage.description) }</div>

                </div>
                {
                    lessonPage.beauty &&                 
                    <div className="mt-4">
                        <Imgix image={lessonPage.beauty} classes={`object-cover w-full lg:h-full`} />
                    </div>
                }
                <LessonSlices slices={ lessonPage.body } />
            </div>
        </Layout>
    );
}

export default (props) => {
    const doc = props.data.prismic.allLessons.edges.slice(0,1).pop();

    if(!doc) return null;

    return(
        <LessonBody lessonPage={ doc.node } />
    )
}