import React from 'react'
import { linkResolver } from "../../utils/linkResolver"
import { Link } from "gatsby"

export default ({ route }) => (

<Link to={linkResolver(route._meta)} className="">
    <div className="flex items-center">
        <svg className="ml-2 h-5 w-5 text-canred" fill="currentColor" viewBox="0 0 24 24">
            <path d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z"/>
        </svg>
        <span className="ml-2 font-sans font-bold">{ route.title }</span>
    </div>
</Link>

)


                                        
