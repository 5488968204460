import React, { useState } from 'react'
import { RichText } from 'prismic-reactjs'
import Linked from "../partials/linked"
import { linkResolver } from "../../utils/linkResolver"
import Imgix from "../imgix"
import Video from "../partials/video"

const Step = ({ slice, step, total_steps, first_step }) => {

    const [state, setState] = useState({
        loadVideo: false,
    });

    const showVideo = () => {
        setState({
            ...state,
            loadVideo: true,
        });
    };


    return (

        <div className="pb-8">
            <div className="px-6 py-8 max-w-4xl mx-auto md:px-8">
                <div className="max-w-2xl mx-auto">
                    <p className="mt-20 font-caps uppercase tracking-widest text-gray-600 text-sm">Schritt {(step - first_step) +1 } / { total_steps }</p>
                    <h3 className="font-caps uppercase tracking-widest">{ slice.primary.step_title }</h3>
                    <div className="mt-8 richtext">{ RichText.render(slice.primary.step_description, linkResolver) }</div>
                    <div className="">{ slice.primary.lesson && <Linked route={slice.primary.lesson} /> }</div>
                </div>
                <div className="mt-20 grid md:grid-cols-2 gap-4">
                    {
                        slice.fields && slice.fields.map(( field, index ) => (

                            <div key={index} className={`${field.step_video && 'col-span-2'}`}>
                                {
                                    field.step_image && 
                                    <Imgix image={field.step_image} classes="w-full" /> 
                                }
                                {
                                    field.step_alt && 
                                    <div className="">{field.step_alt}</div>
                                }
                                {
                                    field.step_caption &&
                                    <div className="">{field.step_caption}</div>
                                }
                                {
                                    field.step_video && 
                                    <div className="shadow-lg rounded-lg overflow-hidden">
                                        {
                                        state.loadVideo ? 
                                        (
                                            <Video video={field.step_video} />
                                        ) : (
                                            <div className="relative mx-auto w-full ">
                                                <button onClick={showVideo} className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
                                                    <img className="w-full" src={field.step_video.thumbnail_url.split("_")[0]} alt="Nothing" />
                                                    <div className="absolute inset-0 w-full h-full flex items-center justify-center">
                                                        <svg className="h-20 w-20 text-canblue hover:text-canorange" fill="currentColor" viewBox="0 0 84 84">
                                                            <circle opacity="0.9" cx="42" cy="42" r="42" fill="white" />
                                                            <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                                                        </svg>
                                                    </div>
                                                </button>
                                            </div>
                                        )
                                        }
                                    </div>
                                }
                            </div>

                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Step
